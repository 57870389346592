import { Image, Text, View } from '@react-pdf/renderer'
import React from 'react'
import tw from '../../../Utils/tailwind-react-pdf'
import logo from "../../../assets/SRG company logo.png"
import { getImageUrlPath } from '../../../Utils/helper'
import WaterMarkSymbol from '../../../Utils/WaterMarkSymbol'

const Header = ({ data, headingOptions }) => {
    return (
        <View style={tw("flex flex-row w-full justify-between gap-4 items-center  border border-gray-400 w-full h-24 mt-5 p-2 mb-5")}>

            <View>
                <Image style={tw(" rounded-xl  w-44")} src={logo} />
            </View>
            <View style={tw("flex flex-col items-center justify-center text-center")}>
                <Text style={tw("font-bold text-xl text-blue-400")}>

                    {headingOptions == "BUYER" ? data?.buyerName : ""}

                </Text>
                <Text style={tw("font-bold text-xl underline text-center ")}> TEST REPORT</Text>
            </View>
            <View>
                {headingOptions == "BUYER" ?
                    <Image style={tw("rounded-xl w-28")} alt='alter'
                        src={getImageUrlPath(data?.buyerLogo)}
                    /> : ""}
            </View>

        </View>
    )
}

export default Header
