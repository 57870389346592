import React from 'react';
import { Document, Page, Text, Link, StyleSheet, View } from '@react-pdf/renderer';

import tw from '../../../../Utils/tailwind-react-pdf';
// Define styles using react-pdf
const styles = StyleSheet.create({
  container: {
    marginBottom: 10,
  },
  title: {
    fontSize: 12,
    fontWeight: 'bold',

  },
  paragraph: {
    fontSize: 10,
    marginBottom: 5,

    textAlign: 'justify',

  },
  link: {
    color: 'blue',
  },
});

const FactoryAddress = () => (

  <>
    <View style={tw("ml-9")}>
      {/* <Text style={[styles.title]}>
      Laboratory Address: SRG Apparels Limited-Textile Testing Laboratory,  224/1, Ponnan Thottam, Murugampalayam (Po), Tirupur-641687.
      </Text> */}
      {/* <Text style={tw('text-center mt-2 mb-2')}>  Mob. No: 9952785239, E-mail: <Link src='testing.pro@srgapparels.com' style={styles.link}>testing.pro@srgapparels.com</Link> , GST.NO: 33AADCS8162K1Z4</Text> */}
      {/* <Text style={tw(" font-bold text-blue-400")}>
      The Laboratory has been accredited as per ISO/IEC 17025:2017 in chemical & mechanical fields of Textile Testing, to know the Scope of accreditation visit 
www.nabl-india.org certificate no. TC10916 .
      </Text> */}
      <Text style={styles.paragraph}>
        The Content of the test report is for your guidance and should not use for advertisement, evidence and litigation. The Sample is not drawn by the laboratory. Test Results related only to samples received and tested by the laboratory subjected to its general conditions of services are available on request and accessible{' '}
        <Link src="http://www.pinnaclesystems.co.in/" style={styles.link}>http://www.pinnaclesystems.co.in/</Link>. Test report shall not be reproduced except in full, without written approval of the laboratory. Test items will be retained by the laboratory for not more than 90 days.
      </Text>
    </View>
  </>

);

export default FactoryAddress;
