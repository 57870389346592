import React from 'react'
import { useEffect, useState } from "react";
import { useGetPartyQuery } from "../../../redux/ErpServices/PartyMasterServices"
import { Loader } from "../../../Basic/components";
import { reportTypeOptions, showEntries } from '../../../Utils/DropdownData';
import secureLocalStorage from 'react-secure-storage';
import { findFromList, getDateFromDateTimeToDisplay, pageNumberToReactPaginateIndex, reactPaginateIndexToPageNumber } from '../../../Utils/helper';
import ReactPaginate from 'react-paginate';
import { useGetRegistrationQuery, useUpdateRegistrationMutation } from '../../../redux/LabServices/RegistrationServices';
import { useGetPackageMasterQuery } from '../../../redux/LabServices/PackageMasterServices';
import { AMENDMENT_ICON, BACKWARD_ICON_PDF, VIEW } from '../../../icons';
import Modal from '../../../UiComponents/Modal';
import TestReportContainerPreview from './TestReportContainerPreview';
import ResultStatusTracking from './ResultStatusTracking';
import AmendmentForm from './AmendmentForm';
import { toast } from 'react-toastify';
import ProfomaInvoice from '../Registration/ProfomaInvoice';
import SampleReceiptDocument from '../DashBoard/SampleReceiptDocument';
import InvoiceReportContainer from '../DashBoard/InvoiceReportContainer';


const CommonDashboard = ({
  heading = "Action Dashboard",
  onClick,
  isAdmin,
  fromDate, toDate, isOverAllReport,

}) => {


  const [activeRegistrationId, setActiveRegistrationId] = useState("")


  const branchId = secureLocalStorage.getItem(
    sessionStorage.getItem("sessionId") + "currentBranchId"
  )
  const [searchDocId, setSearchDocId] = useState("");
  const [searchRegDate, setSearchRegDate] = useState("");
  const [searchDueDate, setSearchDueDate] = useState("");
  const [searchSupplierName, setSearchSupplierName] = useState("");
  const [searchBuyerName, setSearchBuyerName] = useState("");
  const [searchPackageName, setSearchPackageName] = useState("");
  const [dataPerPage, setDataPerPage] = useState("10");
  const [totalCount, setTotalCount] = useState(0);
  const [currentPageNumber, setCurrentPageNumber] = useState(1);
  const [reportType, setReportType] = useState("CURRENT")
  const [profomaInvoice, setProfomaInvoice] = useState(false);
  const [sampleAcknowledgement, setSampleAcknowledgement] = useState(false)

  const [TestReport, setTestReport] = useState(false);
  const [resultEntryTracking, setResultEntryTracking] = useState(false);
  const [invoiceReport, setInvoiceReport] = useState(false);

  const [passFail, setPassFail] = useState("ALL");
  const [splitTest, setSplitTest] = useState("ALL");

  const [amendmentForm, setAmendmentForm] = useState(false)
  const [isValidReport, setIsValidReport] = useState(false)

  const handleOnclick = (e) => {
    setCurrentPageNumber(reactPaginateIndexToPageNumber(e.selected));
  }
  const searchFields = { searchDocId, searchRegDate, searchSupplierName, searchBuyerName, searchDueDate, searchPackageName }

  useEffect(() => { setCurrentPageNumber(1) }, [dataPerPage, searchDocId, searchRegDate, searchSupplierName, searchBuyerName, searchDueDate, searchPackageName, reportType])

  const companyId = secureLocalStorage.getItem(
    sessionStorage.getItem("sessionId") + "userCompanyId"
  )
  const params = {
    branchId, companyId
  };

  const { data: allData, isLoading, isFetching } = useGetRegistrationQuery({ params: { branchId, ...searchFields, dataPerPage, isCommonDashboard: true, pageNumber: currentPageNumber, fromDate, toDate, isOverAllReport: true } });
  const { data: packageList, isLoading: isPackageLoading, isFetching: isPackageFetching } = useGetPackageMasterQuery({ params })
  const { data: supplierList, isLoading: isSupplierLoading, isFetching: isSupplierFetching } =
    useGetPartyQuery({ params: { ...params } });

  useEffect(() => {
    if (allData?.totalCount) {
      setTotalCount(allData?.totalCount)
    }
  }, [allData, isLoading, isFetching])

  useEffect(() => {
    let registrationFormReportComponent = document.getElementById("registrationFormReport");
    registrationFormReportComponent?.addEventListener('keydown', function (ev) {
      var focusableElementsString = '[tabindex="0"]';
      let ol = document.querySelectorAll(focusableElementsString);
      if (ev.key === "ArrowDown") {
        for (let i = 0; i < ol.length; i++) {
          if (ol[i] === ev.target) {
            let o = i < ol.length - 1 ? ol[i + 1] : ol[0];
            o.focus(); break;
          }
        }
        ev.preventDefault();
      } else if (ev.key === "ArrowUp") {
        for (let i = 0; i < ol.length; i++) {
          if (ol[i] === ev.target) {
            let o = ol[i - 1];
            o.focus(); break;
          }
        }
        ev.preventDefault();
      }
    });
    return () => {
      registrationFormReportComponent?.removeEventListener('keydown', () => { });
    };
  }, []);

  const [updateData] = useUpdateRegistrationMutation();


  const handleUpdateRegistration = async (callback, registerdata, text, isDataClear = false) => {
    try {
      await callback(registerdata).unwrap();
      toast.success(text + "Successfully");
    } catch (error) {
      console.log("handle");
    }
  };

  const saveData = (registrationId, isDataClear = false) => {
    if (!window.confirm("Are you sure Revert the Results ...?")) {
      return;
    }
    handleUpdateRegistration(updateData, {
      registrationId, isDashboardRevert: true
    }, "Revert To Admin", isDataClear);
  };



  function getDateTimeRange(date) {
    let startTime = new Date(date);
    startTime.setHours(0, 0, 0, 0);
    let endTime = new Date(date);
    endTime.setHours(23, 59, 59, 99);
    return { startTime, endTime };
  }


  function checkReportPeriod(logoutDate, array) {
    let today = new Date()
    let validays = array?.find(val => val.isSelected)?.days
    const { startTime: startDateStartTime, endTime: startDateEndTime } = getDateTimeRange(logoutDate);
    const { startTime: endDateStartTime, endTime: endDateEndTime } = getDateTimeRange(today);
    let logout = new Date(logoutDate)

    logout.setDate(logout?.getDate() + parseInt(validays || 90))

    if (new Date(logout) >= new Date(today)) {

      setIsValidReport(true)
    }
    else {

      setIsValidReport(false)
    }
    return
  }


  const isLoadingIndicator = isLoading || isFetching || isSupplierFetching || isSupplierLoading || isPackageFetching || isPackageLoading

  if (isLoading || isFetching) return <Loader />

  return (
    <>
      <Modal isOpen={TestReport} onClose={() => setTestReport(false)} widthClass={"w-[90%] h-[90%]  bg-white p-1"}  >
        <TestReportContainerPreview onClose={() => setTestReport(false)} splitTest={splitTest} setSplitTest={setSplitTest} activeRegistrationId={activeRegistrationId}
          passFail={passFail} setPassFail={setPassFail} allData={allData?.data} isPreview={true} isDashBoard={true} />
      </Modal>
      <Modal isOpen={profomaInvoice} onClose={() => setProfomaInvoice(false)} widthClass={"px-2 h-[90%] w-[90%] bg-white p-1"}>
        <ProfomaInvoice id={activeRegistrationId} />


      </Modal>
      <Modal isOpen={sampleAcknowledgement} onClose={() => setSampleAcknowledgement(false)} widthClass={"px-2 h-[90%] w-[90%] bg-white p-1"}>
        <SampleReceiptDocument activeRegistrationId={activeRegistrationId} />


      </Modal>
      <Modal isOpen={invoiceReport} onClose={() => setInvoiceReport(false)} widthClass={"w-[90%] h-[90%]  bg-white p-1"} >

        <InvoiceReportContainer activeRegistrationId={activeRegistrationId} allData={allData?.data} />
      </Modal>

      <div id='registrationFormReport' className="flex flex-col w-full h-[95%] overflow-auto">
        <div className="md:flex md:items-center md:justify-between page-heading p-1">
          <div className="heading text-center md:mx-10">{heading}</div>
          <div className='flex'>
            <div className=" sub-heading justify-center md:justify-start items-center">
              <label className="text-white text-sm rounded-md m-1  border-none">Show Entries</label>
              <select value={dataPerPage}
                onChange={(e) => setDataPerPage(e.target.value)} className='h-6 w-40 border border-gray-500 rounded mr-9'>
                {showEntries.map((option, index) => <option key={index} value={option.value} >{option.show}</option>)}
              </select>
            </div>
          </div>
        </div>
        <>
          <div
            className="h-[500px] overflow-auto w-full"
          >
            <table className="table-auto text-center w-full">
              <thead className="border-2 table-header">
                <tr className='h-2'>
                  <th
                    className="border-2 p-0.5 top-0 stick-bg w-10"
                  >
                    S. no
                  </th>
                  <th
                    className="w-36 border-2 p-0.5 top-0 stick-bg"
                  >
                    <div>Reg. No</div><input
                      type="text"
                      className="text-black w-36  focus:outline-none p-1 border  border-gray-400 rounded-lg"
                      placeholder="Search"
                      value={searchDocId}
                      onChange={(e) => {
                        setSearchDocId(e.target.value);
                      }}
                    />
                  </th>
                  <th
                    className="w-24 border-2 p-0.5 stick-bg"
                  >
                    <div>Reg. Date</div><input
                      type="text"
                      className="text-black w-28 focus:outline-none border p-1 border-gray-400 rounded-lg"
                      placeholder="Search"
                      value={searchRegDate}
                      onChange={(e) => {
                        setSearchRegDate(e.target.value);
                      }}
                    />
                  </th>
                  <th
                    className="w-36 border-2 p-0.5 top-0 stick-bg"
                  >
                    <div>Supplier</div><input
                      type="text"
                      className="text-black w-36 focus:outline-none border p-1 border-gray-400 rounded-lg"
                      placeholder="Search"
                      value={searchSupplierName}
                      onChange={(e) => {
                        setSearchSupplierName(e.target.value);
                      }}
                    />
                  </th>
                  <th
                    className="border-2 w-24 p-0.5 top-0 stick-bg"
                  >
                    <div>Due Date</div><input
                      type="text"
                      className="text-black w-24 focus:outline-none border p-1 border-gray-400 rounded-lg"
                      placeholder="Search"
                      value={searchDueDate}
                      onChange={(e) => {
                        setSearchDueDate(e.target.value);
                      }}
                    />
                  </th>
                  <th
                    className="border-2 p-1 top-0 stick-bg"
                  >
                    <div>Buyer</div><input
                      type="text"
                      className="text-black  focus:outline-none border p-1 border-gray-400 rounded-lg"
                      placeholder="Search"
                      value={searchBuyerName}
                      onChange={(e) => {
                        setSearchBuyerName(e.target.value);
                      }}
                    />
                  </th>
                  <th className="border-2 p-0.5 top-0 stick-bg">
                    <div>Package</div><input
                      type="text"
                      className="text-black  focus:outline-none border p-1 w-60 border-gray-400 rounded-lg"
                      placeholder="Search"
                      value={searchPackageName}
                      onChange={(e) => {
                        setSearchPackageName(e.target.value);
                      }}
                    />
                  </th>

                  <th className="border-2   stick-bg text-center" >
                    Test Report
                  </th>
                  <th className="border-2   stick-bg text-center" >
                    Profoma Invoice
                  </th>
                  <th className="border-2   stick-bg text-center" >
                    Sample Acknowledgement
                  </th>
                  <th className="border-2   stick-bg text-center" >
                    Invoice Report
                  </th>
                  {/* <th className="border-2   stick-bg text-center" >
                    Result Entry Status
                  </th> */}

                </tr>
              </thead>

              <tbody className="border-2">{console.log(allData, "alldataaacommo")}
                {allData?.data?.map((dataObj, index) => (
                  <tr
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        onClick(dataObj.id);
                      }
                    }}
                    tabIndex={0}
                    key={dataObj.id}
                    className="border-2 table-row cursor-pointer"
                  >
                    <td className='py-1'> {(index + 1) + (dataPerPage * (currentPageNumber - 1))}</td>
                    <td className='py-1'> {dataObj.docId}</td>
                    <td className='py-1 w-24'>{getDateFromDateTimeToDisplay(dataObj.createdAt)} </td>
                    <td className='py-1'>{findFromList(dataObj.supplierId, supplierList?.data, "name")}</td>
                    <td className='py-1'>{getDateFromDateTimeToDisplay(dataObj.dueDate)}</td>
                    <td className='py-1'>{findFromList(dataObj.buyerId, supplierList?.data, "name")}</td>
                    <td className='py-1'>{findFromList(dataObj.packageId, packageList?.data, "name")}</td>
                    <td className='py-1 text-center' onClick={() => { setActiveRegistrationId(dataObj.id); setTestReport(true); }}>
                      {VIEW}
                    </td>
                    <td className='py-1 text-center' onClick={() => {
                      setProfomaInvoice(true);
                      setActiveRegistrationId(dataObj.id);
                    }}>
                      {VIEW}
                    </td>
                    <td className='py-1 text-center' onClick={() => { setActiveRegistrationId(dataObj.id); setSampleAcknowledgement(true) }}>
                      {VIEW}
                    </td>
                    <td className='py-1 text-center' onClick={() => { setActiveRegistrationId(dataObj.id); setInvoiceReport(true) }}>

                      {VIEW}


                    </td>
                    {/* <td className='py-1' onClick={() => { setActiveRegistrationId(dataObj.id); setResultEntryTracking(true) }}> {VIEW} </td> */}

                  </tr>
                ))}
              </tbody>

            </table>
          </div>
        </>
        <div>


          <ReactPaginate
            previousdiv={"<"}
            nextdiv={">"}
            breakdiv={"..."}
            breakClassName={"break-me"}
            forcePage={pageNumberToReactPaginateIndex(currentPageNumber)}
            pageCount={Math.ceil(totalCount / dataPerPage)}
            marginPagesDisplayed={1}
            onPageChange={handleOnclick}
            containerClassName={"flex justify-center m-2 gap-5 items-center"}
            pageClassName={"border custom-circle text-center"}
            disabledClassName={"p-1 bg-gray-200"}
            previousLinkClassName={"border p-1 text-center"}
            nextLinkClassName={"border p-1"}
            activeClassName={"bg-blue-900 text-white px-2"} />
        </div>

      </div>


    </>
  )
}

export default CommonDashboard