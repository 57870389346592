import React from 'react'
import logo from "../../../assets/SRG company logo.png"
import empty from "../../../assets/empty.png"
import { getImageUrlPath } from '../../../Utils/helper'
const HeaderLogo = ({ data, headingOptions }) => {

  const picture = data?.buyerLogo

  // const imageFormatter = () => {
  //   if (picture) {
  //     if (typeof (picture) === "object") {
  //       return URL.createObjectURL(picture)
  //     } else {

  //       return getImageUrlPath(picture)
  //     }
  //   }
  //   return null
  // }

  const imageWidth = "70px"
  const imageHeight = "70px";


  return (
    <div className='w-full  border border-gray-400  mt-5 mb-3'>

      <div className="flex justify-between ">
        <div className=" border-r  border-gray-400">
          <img className=" rounded-xl m-2 mt-4 w-56" src={logo} />
        </div>
        <div className="text-center" >
          <h1 className="font-bold m-2 text-2xl text-blue-400">{headingOptions == "BUYER" ? data?.buyerName : ""}</h1>

          <h1 className="font-bold m-2 text-xl underline underline-offset-4"> TEST REPORT</h1>
        </div>

        <div className='border-l border-gray-400'>
          {
            headingOptions == "BUYER" ?
              Boolean(picture) ?
                <img className='m-2' style={{ height: imageHeight, width: imageWidth, objectFit: 'cover' }}
                  src={getImageUrlPath(picture)}
                />
                :
                <img src={empty} className='w-20' />

              : ""
          }
        </div>

      </div>






      {/* <table className="w-full">
        <thead>
        <tr>
        <th className="mt-2 ">
          <img className="bg-gray-400 rounded-xl  w-36" src={logo} />
        </th>
        <th className="" >
          <div>
          <h1 className="font-bold text-2xl text-blue-400">{headingOptions=="BUYER" ?  data?.buyerName :""}</h1>

<h1 className="font-bold text-xl underline underline-offset-4"> TEST REPORT</h1>
          </div>
          
        </th>

        <th className=''>
        {
        headingOptions=="BUYER" ?
          Boolean(picture) ?
            <img style={{ height: imageHeight, width: imageWidth, objectFit: 'cover' }}
              src={getImageUrlPath(picture)}
            />
            :
            <img src={empty} className='w-20' />
          
          :""
        }
        </th>
        </tr>
        </thead>
       
       

      </table> */}
    </div>

  )
}

export default HeaderLogo