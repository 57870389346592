import React, { useEffect, useState } from 'react'
import { getImageUrlPath } from '../../../Utils/helper'
import TestLists from './TestLists'
import moment from 'moment'
import empty from "../../../assets/empty.png"
import { useUpdateRegistrationMutation } from '../../../redux/LabServices/RegistrationServices'
import { toast } from 'react-toastify'
const TestResultsList = ({ activeRegistrationId, reportData, testComments, setTestComments, passFail, splitTest }) => {
  const [executiveSummary, setExecutiveSummary] = useState("")


  useEffect(() => {
    setExecutiveSummary(reportData?.executiveSummary)
  }, [setExecutiveSummary, reportData, activeRegistrationId])

  const [updateData] = useUpdateRegistrationMutation();
  const handleSubmitCustom = async (callback, executiveSummaryData, text, isDataClear = false) => {
    try {
      await callback(executiveSummaryData).unwrap();
      toast.success(text + "Successfully");
    } catch (error) {
      console.log("handle");
    }
  };

  const saveData = (executiveSummary, isDataClear = false) => {

    if (!window.confirm("Are you sure save the details ...?")) {
      return;
    }
    handleSubmitCustom(updateData, {
      executiveSummary, registrationId: activeRegistrationId,
      id: activeRegistrationId, executiveSummaryUpdate: true
    }, "Updated", isDataClear);

  };


  useEffect(() => {
    splitTest == "ACCREDITATION" ? setTestComments(reportData?.testDetailsSummary?.accredidatedResults) : splitTest == "NONACCREDITATION" ? setTestComments(reportData?.testDetailsSummary?.nonAccredidatedResults) : setTestComments(reportData?.testDetailsSummary?.allResults)


  }, [reportData, setTestComments, splitTest])


  const imageWidth = "200px"
  const imageHeight = "150px";

  const image = reportData?.authorizedSignature ? reportData?.authorizedSignature : ""



  return (

    <div className='ml-5 page'>
      <div className='gap-y-3 grid grid-cols-1 place-items-start w-full place-content-center p-1 '>

        {
          splitTest == "ACCREDITATION" ?

            <h1><strong>Test Report No :</strong>{reportData?.testReportNo} - A</h1>
            :
            splitTest == "NONACCREDITATION" ?
              <h1><strong>Test Report No :</strong>{reportData?.testReportNo} - B</h1>
              :
              <h1><strong>Test Report No :</strong>{reportData?.testReportNo}</h1>

        }

        <h1>{splitTest == "ACCREDITATION" && reportData?.testDetailsSummary?.accredidatedResults.length > 0 && reportData?.ulrNo ? `${reportData?.ulrNo}${"F"}` : splitTest == "NONACCREDITATION" ? "" : splitTest == "ALL" && reportData?.testDetailsSummary?.nonAccredidatedResults.length > 0 && reportData?.ulrNo ? `${reportData?.ulrNo}${"P"}` : reportData?.ulrNo ? `${reportData?.ulrNo}${"F"}` : "--"}</h1>


        <h1><strong>Date Out :</strong>{moment.utc(reportData?.logoutDate).format("DD-MM-YYYY")}</h1>
        {reportData?.amendmentReason &&
          <>
            <h1><strong>Amendment  <span>{reportData?.testReportNo?.slice(-1)}</span>  Date :</strong>{moment.utc(reportData?.amendmentLogOutDate).format("DD-MM-YYYY")}  </h1>

          </>
        }

      </div>
      <div className=' w-full mt-9'>
        <table className='w-3/4 border border-gray-500 ml-16 table-auto'>
          <thead>

            <tr className='text-xs border border-gray-500'>
              <th className='p-0.5  border border-gray-500'>Test Property </th>
              <th className='p-0.5 border border-gray-500'>Pass</th>
              <th className='p-0.5 border border-gray-500'>Fail</th>
              <th className='p-0.5 border border-gray-500'>Data</th>
              <th className='p-0.5 border border-gray-500'>Comments</th>
            </tr>

          </thead>

          <tbody>
            {(testComments ? testComments : []).map((test, testIndex) =>

              <tr key={testIndex} className="border border-black ">
                <TestLists activeRegistrationId={activeRegistrationId} testComments={testComments} setTestComments={setTestComments} passFail={passFail} test={test} testIndex={testIndex} splitTest={splitTest} />

              </tr>
            )}
          </tbody>
        </table>
        {
          splitTest == "ACCREDITATION" ?

            <div className='ml-16 mt-5 w-3/4'>
              <h1 className='font-bold'>Note :</h1>
              <h1>The NABL Symbol is published only on the Page(s) having NABL accredited tests.</h1>
            </div>
            :
            splitTest == "ALL" && reportData?.testDetailsSummary?.accredidatedResults.length > 0 ?

              <div className=' mt-5 w-3/4'>
                <h1 className='font-bold'>Note :</h1>
                <h1>The NABL Symbol is published only on the Page(s) having NABL accredited tests.</h1>
              </div> : ""


        }

        <div className='mt-2 font-bold'>ExecutiveSummary :</div>
        <div className='flex gap-x-3 mt-3 h-44 w-full ml-20'>

          <textarea className=" w-3/4 focus:outline-none border border-gray-500 rounded p-2 capitalize"
            value={executiveSummary} onChange={(e) => { setExecutiveSummary(e.target.value) }} ></textarea>

          <button

            className='bg-green-400 rounded-lg border border-black text-xs w-16 h-9 p-1 mt-16'
            onClick={() => { saveData(executiveSummary ? executiveSummary : "") }}
          >Save

          </button>

        </div>

        <div className=' p-1'>
          <div className='w-full font-bold'>

            Authority Signatory :

          </div>

          <div className='w-ful mt-2 mb-2'>
            {Boolean(image) ?
              <img style={{ height: imageHeight, width: imageWidth, objectFit: 'fit' }}
                src={getImageUrlPath(image)}
              />
              :
              <img src={empty} className='w-20 h-20 text-center' />
            }
          </div>
        </div>

        <div className='w-3/4'>

          <h1 className=''>P.Tamilarasan</h1>
          <h1 className=''>Manager</h1>
          <h1 className=''>Pinnacle-Systems-Textile Testing Laboratory</h1>



        </div>
        <div className=' mt-5 w-3/4 flex'>

          <h1 className='font-bold'>Please Contact::</h1>

          <h1 className='flex'>For any Technical Issues / Complaint: N. Sethuraman- <a href='testing.pro@srgapparels.com' className='ml-1'>testing.pro@srgapparels.com</a></h1>

        </div>
      </div>


    </div>
  )
}

export default TestResultsList