import React from 'react'
import { getImageUrlPath } from '../../../../Utils/helper'
import TestLists from './TestLists'
import { Image, Text, View, Link } from '@react-pdf/renderer'
import tw from '../../../../Utils/tailwind-react-pdf'

const TestResultsList = ({ reportData, testComments, passFail, splitTest }) => {
  const imageWidth = "100px"
  const imageHeight = "50px";
  const image = reportData?.authorizedSignature ? reportData?.authorizedSignature : ""
  return (

    <View style={tw('ml-9 page')}>
      <View style={tw(' w-full mt-7')}>
        <View style={tw('text-xs  flex flex-row w-full text-center ')}>
          <Text style={[tw('border  border-gray-500 w-[45%] p-1'), { fontWeight: 900, fontFamily: "Times-Bold" }]}>Test Property </Text>
          <Text style={[tw('border-t border-b border-r  border-gray-500 w-[8%] p-1'), { fontWeight: 900, fontFamily: "Times-Bold" }]}>Pass</Text>
          <Text style={[tw('border-t border-b border-r  border-gray-500 w-[8%] p-1'), { fontWeight: 900, fontFamily: "Times-Bold" }]}>Fail</Text>
          <Text style={[tw('border-t border-b border-r  border-gray-500 w-[8%] p-1'), { fontWeight: 900, fontFamily: "Times-Bold" }]}>Data</Text>
          <Text style={[tw('border-t border-b border-r  border-gray-500 w-[18%] p-1'), { fontWeight: 900, fontFamily: "Times-Bold" }]}>Comments</Text>
        </View>
        <View>
          {(testComments ? testComments : []).map((test, testIndex) =>
            <View key={testIndex} style={tw(" ")}>
              <TestLists passFail={passFail} test={test} />
            </View>
          )}
        </View>
        {
          splitTest === "ACCREDITATION" ?
            <View style={tw(' mt-5 w-3/4 flex flex-row gap-x-2')}>
              <Text style={[tw('font-bold'), { fontWeight: 900, fontFamily: "Times-Bold" }]}>Note :</Text>
              <Text style={tw("")}>The NABL Symbol is published only on the Page(s) having NABL accredited tests.</Text>
            </View>
            :
            splitTest === "ALL" && reportData?.testDetailsSummary?.accredidatedResults.length > 0 ?
              <View style={tw(' mt-5 w-3/4 flex flex-row gap-x-2')}>
                <Text style={[tw('font-bold'), { fontWeight: 900, fontFamily: "Times-Bold" }]}>Note :</Text>
                <Text style={tw("")}>The NABL Symbol is published only on the Page(s) having NABL accredited tests.</Text>
              </View> : ""
        }
        <Text style={[tw('mt-3 font-bold'), { fontWeight: 900, fontFamily: "Times-Bold" }]}>
          ExecutiveSummary :
        </Text>
        <View style={tw(' px-5 ml-10')}>
          <Text style={tw(" w-full break-keep")}>
            {reportData?.executiveSummary}
          </Text>
        </View>
        <View style={tw('pt-1')}>
          <Text style={[tw('w-full font-bold'), { fontWeight: 900, fontFamily: "Times-Bold" }]}>
            Authority Signatory :
          </Text>
          <View style={tw('w-ful mt-2 mb-2')}>
            <Image style={{ height: imageHeight, width: imageWidth, objectFit: 'fit' }}
              src={getImageUrlPath(image)}
            />
          </View>
        </View>
        <View style={tw('w-3/4 ')}>
          <Text >P.Tamilarasan</Text>
          <Text >Manager</Text>
          <Text >Pinnacle-Systems-Textile Testing Laboratory</Text>
        </View>
        <View style={tw(' mt-5 w-3/4 flex flex-row gap-x-2')}>
          <Text style={[tw('font-bold'), { fontWeight: 900, fontFamily: "Times-Bold" }]}>Please Contact::</Text>
          <Text style={tw('flex')}>For any Technical Issues / Complaint: P.Manoj-  <Link src="pinnacle.systems.ps@gmail.com" style={tw("text-blue-500")}>pinnacle.systems.ps@gmail.com</Link>. </Text>
        </View>
      </View>
    </View>
  )
}

export default TestResultsList